import type { Ref } from "vue";
import type {
  AvailableRouterMethod as _AvailableRouterMethod,
  NitroFetchRequest,
} from "nitropack";
import type { UseFetchOptions, FetchResult } from "nuxt/app";
import type { FetchError } from "ofetch";
import { hash } from "ohash";
import type { AsyncData, KeysOf, PickFrom } from "#app/composables/asyncData";

type AvailableRouterMethod<R extends NitroFetchRequest> =
  _AvailableRouterMethod<R>;

export function useApi<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void
    ? "get" extends AvailableRouterMethod<ReqT>
      ? "get"
      : AvailableRouterMethod<ReqT>
    : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null,
>(
  url: Ref<ReqT> | ReqT | (() => ReqT),
  options?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>,
): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null> {
  options ||= {};
  if (!options?.key) {
    options.key =
      getRequestUrl(url) +
      "__" +
      hash([
        unref(options?.method as MaybeRef<string | undefined>)?.toUpperCase() ||
          "GET",
        unref(options?.baseURL),
        unref(options?.query || options?.params),
      ]);
  }

  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$api,
  });
}

function getRequestUrl<ReqT extends NitroFetchRequest = NitroFetchRequest>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
): string {
  let url = "";
  if (typeof request === "function") {
    url = request().toString();
  } else {
    url = unref(request).toString();
  }
  return url;
}
